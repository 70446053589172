<template>
  <div class="cost-chart-container">
    <pieChart
      v-if="costTotal > 0"
      :chartData="chartData"
      :options="options"
    ></pieChart>

    <div v-else class="grey--text">
      圖表無資料
    </div>
  </div>
</template>

<script>
import accountingConstants from 'modules/accounting/config/accountingConstants'
export default {
  props: {
    costBreakdown: {
      type: Object,
      required: true
    },
    costTotal: {
      type: Number,
      required: true
    }
  },
  computed: {
    typeLabels() {
      return accountingConstants.COST_TYPES.map(type => this.$t(`cost.type.${type}`))
    },
    costs() {
      return accountingConstants.COST_TYPES.map(type => this.costBreakdown[type])
    },
    chartData() {
      return {
        labels: this.typeLabels,
        datasets: [{
          data: this.costs,
          backgroundColor: [
            '#2196F3',
            '#3F51B5',
            '#00BCD4',
            '#009688',

            '#CDDC39',
            '#4CAF50',
            '#FFC107',
            '#607D8B',

            '#795548',
            '#000000',
            '#F4511E',
            '#EC407A',
            '#F44336',
          ],
          hoverOffset: 4
        }]

      }
    },
    options() {
      return {}
    },
  },
  components: {
    pieChart: () => import('components/charts/pieChart.vue')
  },
}
</script>


<style lang="sass" type="text/sass">
.cost-chart-container
  width: 100%
  height: 100%
  max-width: 400px
  max-height: 400px
  // border: 1px solid #d3d3d3
</style>